import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Approver,
  EmployeeWorkDetailPayroll,
  GetPayrollDetailsResponse,
  PayrollDetailsRequest,
  PayrollStatus,
} from '../../types/Payroll'
import { Tenant } from '../../context/AuthProvider'
import { Farm } from '../../types/Farm'
import { getFullName, PaymentType } from '../../types/Employee'
import { fDateShort } from '../../utils/format-time'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { AxiosError } from 'axios'
import { useSnackbar } from '../../context/SnackbarProvider'
import ReportSkeletons from './ReportSkeletons'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import { fNumber } from '../../utils/format-number'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import PayrollFilters from './PayrollFilters'
import { useAuth } from '../../context/AuthProvider'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import { green } from '@mui/material/colors'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PayrollEmployeeWorkDetails from './PayrollEmployeeWorkDetails'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { usePayrollPay } from '../../context/PayrollPayProvider'

const MIN_LOADING_TIME = 500

type MenuOption = {
  name: string
  isEnable: boolean
  action: () => void
}

interface PayrollPayIncludedProps {
  payrollId: number
  onApprovePayroll: () => void
  onClosePayroll: () => void
  onDeletePayroll: () => void
  showApprovePayrollFeature: boolean
  setShowApprovePayrollFeature: (showApproveFeature: boolean) => void
  payrollApprovedByCurrentUser: boolean
  payrollApprovedByAllApprovers: boolean
  setPayrollOptionsEnabled: (enabled: boolean) => void
}

const PayrollPayIncluded: FC<PayrollPayIncludedProps> = ({
  payrollId,
  setShowApprovePayrollFeature,
  payrollApprovedByCurrentUser,
  payrollApprovedByAllApprovers,
  setPayrollOptionsEnabled,
}) => {
  const { showError } = useSnackbar()
  const [payrolDetails, setPayrolDetails] = useState<GetPayrollDetailsResponse>()
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
  const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null)
  const [selectedJobTypeIds, setSelectedJobTypeIds] = useState<number[]>([])
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [checkboxEmployeeStates, setCheckboxEmployeeStates] = useState<boolean[]>([])
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<number[]>([])
  const [payrollApprovers, setPayrollApprovers] = useState<Approver[]>([])
  const { auth } = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openPayrollDetailMenu = Boolean(anchorEl)
  const [openPayrollDetailPopup, setOpenPayrollDetailPopup] = useState(false)
  const [selectedEmployeeWorkDetail, setSelectedEmployeeWorkDetail] = useState<EmployeeWorkDetailPayroll | null>(null)
  const { payrollUpdated } = usePayrollPay()  
    
  const payrollDetailMenuOptions: MenuOption[] = [
    {
      name: 'Editar',
      isEnable: true,
      action: () => {
        setOpenPayrollDetailPopup(true)
      },
    },
  ]

  useEffect(() => {
    setPayrollApprovers((prevApprovers) => {
      return prevApprovers.map((approver) => {
        if (approver.id === auth.user?.userid || 0) {
          return {
            ...approver,
            approved: true,
          }
        }
        return approver
      })
    })
    
    if (payrollApprovedByAllApprovers) {
      setPayrolDetails((prevPayrollDetails) => {
        if (!prevPayrollDetails) return prevPayrollDetails
        return {
          ...prevPayrollDetails,
          payrollStatus: PayrollStatus.Approved,
        }
      })
    }
    
  }, [auth.user?.userid, payrollApprovedByAllApprovers, payrollApprovedByCurrentUser])

  const payrollDetailsRequest: PayrollDetailsRequest = useMemo(
    () => ({      
      tenantId: selectedTenant ? selectedTenant.id : null,
      farmId: selectedFarm ? selectedFarm.id : null,
      employeeJobTypeIds: selectedJobTypeIds,
      paymentTypeId: selectedPaymentType ? selectedPaymentType.id : null,
      payrollMasterId: payrollId,
      fromDate: null,
      toDate: null,
    }),
    [
      selectedTenant,
      selectedFarm,
      selectedJobTypeIds,
      selectedPaymentType,
      payrollId,
    ]
  )

  const handleCheckboxEmployeeChange = (index: number, employeeId: number) => {
    const newCheckboxStates = [...checkboxEmployeeStates]
    newCheckboxStates[index] = !newCheckboxStates[index]
    setCheckboxEmployeeStates(newCheckboxStates)

    if (newCheckboxStates[index]) {
      setSelectedEmployeeIds((prevSelected) => [...prevSelected, employeeId])
    } else {
      setSelectedEmployeeIds((prevSelected) =>
        prevSelected.filter((id) => id !== employeeId)
      )
    }
  }

  const isAnyCheckboxEmployeeSelected = checkboxEmployeeStates.some((checked) => checked)
  const areAllCheckboxEmployeesSelected = checkboxEmployeeStates.length > 0 && checkboxEmployeeStates.every((checked) => checked)

  const handleSelectAllCheckboxEmployees = () => {
    const allSelected = areAllCheckboxEmployeesSelected
    const newCheckboxStates = checkboxEmployeeStates.map(() => !allSelected)
    setCheckboxEmployeeStates(newCheckboxStates)

    if (allSelected) {
      setSelectedEmployeeIds([])
    } else {
      const allEmployeeIds =
        payrolDetails?.employeeWorkDetails.map(
          (report) => report.employee.id
        ) || []
      setSelectedEmployeeIds(allEmployeeIds)
    }
  }

  useEffect(() => {
    setPayrollOptionsEnabled(selectedEmployeeIds.length === 0)
  }, [selectedEmployeeIds, setPayrollOptionsEnabled])

  const getJournalDailyWorkReports = useCallback(
    async (controller: AbortController) => {
      setIsLoading(true)
      try {
        const fetchStart = Date.now()

        const responsePromise = axiosPrivate.post(
          endpoints.getPayrollDetails,
          payrollDetailsRequest,
          { signal: controller.signal }
        )

        const [response] = await Promise.all([
          responsePromise,
          new Promise((resolve) =>
            setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
          ), // Ensure a minimum loading time
        ])

        const payrollResponse = response.data as GetPayrollDetailsResponse
        setPayrolDetails(payrollResponse)
        setPayrollApprovers(payrollResponse.approvers || [])
        setShowApprovePayrollFeature(
          payrollResponse.payrollStatus === PayrollStatus.Created &&
            (auth.user?.isApprover ?? false) &&
            payrollResponse.approvers.some(
              (approver: Approver) =>
                approver.approved === false && approver.id === auth.user?.userid
            )
        )
        setCheckboxEmployeeStates(
          new Array(payrollResponse.employeeWorkDetails.length).fill(false)
        )
        setIsLoading(false)
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          setIsLoading(false)
          showError('Error al obtener los datos de la planilla')
        }
      }
    },
    [
      auth.user?.isApprover,
      auth.user?.userid,
      payrollDetailsRequest,
      setPayrollApprovers,
      setShowApprovePayrollFeature,
      showError,
    ]
  )

  useEffect(() => {
    const controller = new AbortController()
    getJournalDailyWorkReports(controller)
    return () => {
      controller.abort()
    }
  }, [
    selectedTenant,
    selectedFarm,
    selectedJobTypeIds,
    selectedPaymentType,
    getJournalDailyWorkReports,
    payrollUpdated
  ])

  const handleClickPayrollDetailMenu = (event: React.MouseEvent<HTMLElement>, employeeWorkDetail: EmployeeWorkDetailPayroll) => {
    setAnchorEl(event.currentTarget)
    setSelectedEmployeeWorkDetail(employeeWorkDetail)    
  }

  const handleCloseReportMenu = () => {
    setAnchorEl(null)
  }  

  const onClosePayrollEmployeeWorkDetailsDialog = () => {
    setOpenPayrollDetailPopup(false)
  }

  return (
    <>
      <PayrollFilters
        tenants={payrolDetails?.tenants || []}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        farms={payrolDetails?.farms || []}
        selectedFarm={selectedFarm}
        setSelectedFarm={setSelectedFarm}
        employeeJobTypes={payrolDetails?.employeeJobTypes || []}
        selectedJobTypeIds={selectedJobTypeIds}
        setSelectedJobTypeIds={setSelectedJobTypeIds}
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
      />

      {payrollApprovers.some((approver) => !approver.approved) && (
        <Grid xs={12} md={8} sx={{ mt: 0, mb: 2, ml: 1 }}>
          <Grid xs={12}>
            <Typography variant='h6'>Pendiente de approbar por</Typography>
            <List dense={true}>
              {payrollApprovers
                .filter((approver) => !approver.approved)
                .map((approver) => (
                  <ListItem key={approver.id}>
                    <ListItemText
                      primary={`${approver.firstName} ${approver.lastName}`}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      )}

      {payrolDetails?.payrollStatus === PayrollStatus.Approved &&
        checkboxEmployeeStates.length > 0 && (
          <Button
            variant='contained'
            color={areAllCheckboxEmployeesSelected ? 'warning' : 'inherit'}
            onClick={handleSelectAllCheckboxEmployees}
            sx={{ ml: 2, mb: 2 }}
          >
            {areAllCheckboxEmployeesSelected
              ? 'Deseleccionar todos'
              : 'Seleccionar todos'}
          </Button>
        )}

      <Card>
        <CardContent>
          {isLoading ? (
            <ReportSkeletons count={5} />
          ) : (
            payrolDetails?.employeeWorkDetails.map((report, index) => (
              <Grid xs={12} container spacing={1} sx={{ ml: 1 }} key={index}>
                <Grid xs={12}>
                  <Grid
                    xs={11}
                    container
                    onClick={() =>
                      payrolDetails?.payrollStatus === PayrollStatus.Approved &&
                      handleCheckboxEmployeeChange(index, report.employee.id)
                    }
                    sx={
                      payrolDetails?.payrollStatus === PayrollStatus.Approved
                        ? { cursor: 'pointer' }
                        : {}
                    }
                  >
                    <Grid xs={12} container alignItems='center'>
                      {payrolDetails?.payrollStatus ===
                        PayrollStatus.Approved && (
                        <Checkbox
                          size='small'
                          sx={{ m: 0, p: 0 }}
                          checked={checkboxEmployeeStates[index] || false}
                          onChange={() =>
                            handleCheckboxEmployeeChange(
                              index,
                              report.employee.id
                            )
                          }
                        />
                      )}
                      <Typography variant='h6' sx={{ ml: 1 }}>
                        {getFullName(report.employee)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={1}
                    justifyContent='flex-end'
                    alignItems='center'
                  >
                    <IconButton
                      aria-label='more'
                      id='long-button'
                      size='small'
                      aria-controls={
                        openPayrollDetailMenu ? 'long-menu' : undefined
                      }
                      aria-expanded={openPayrollDetailMenu ? 'true' : undefined}
                      aria-haspopup='true'
                      onClick={(e) => handleClickPayrollDetailMenu(e, report)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Grid>
                  <Menu
                    id='long-menu'
                    anchorEl={anchorEl}
                    open={openPayrollDetailMenu}
                    onClose={handleCloseReportMenu}
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                  >
                    {payrollDetailMenuOptions.map((option) => (
                      <MenuItem
                        key={option.name}
                        onClick={() => {
                          handleCloseReportMenu()
                          if (
                            selectedEmployeeWorkDetail !== null &&
                            option.isEnable
                          ) {
                            option.action()
                          }
                        }}
                        disabled={!option.isEnable}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Menu>

                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: { xs: 'auto', md: 650 } }}
                      size='small'
                      aria-label='a dense table'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Fecha</TableCell>
                          <TableCell>Trabajo</TableCell>
                          <TableCell align='right'>Cantidad</TableCell>
                          <TableCell align='right'>Precio</TableCell>
                          <TableCell align='right'>SubTotal</TableCell>
                          <TableCell>Finca</TableCell>
                          <TableCell>Perfil</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {report.journalDailyWorkDetails.map((detail) => (
                          <TableRow
                            key={detail.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell>
                              {fDateShort(
                                detail.journalDailyWork.journalDailyWorkDate
                              )}
                            </TableCell>
                            <TableCell>{detail.employeeJobType.name}</TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.jobQuantity)}
                            </TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.jobPrice)}
                            </TableCell>
                            <TableCell align='right'>
                              {fNumber(detail.dayPayment)}
                            </TableCell>
                            <TableCell>
                              {detail.journalDailyWork.farm.name}
                            </TableCell>
                            <TableCell>
                              {detail.journalDailyWork.tenant.name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Grid container spacing={1}>
                    <Grid xs={12} md={6}>
                      <Typography
                        variant='body2'
                        fontWeight='bold'
                        sx={{ mx: 1 }}
                      >
                        Total = {fNumber(report.totalPayment)}
                      </Typography>
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant='body2' sx={{ mx: 1 }}>
                        Pago en {report.employee.paymentTypeDescription}{' '}
                        {report.accountNumber
                          ? `(${report.accountNumber})`
                          : ''}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid xs={12} container sx={{ mx: 1, mt: 2, mb: 2 }}>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}

          <Grid xs={12} container sx={{ mx: 1, mt: 3, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>

          <Grid xs={12} container spacing={1} sx={{ ml: 1 }}>
            <TextField
              label='Total Planilla'
              value={fNumber(payrolDetails?.totalPayrollPayment)}
              fullWidth
              InputProps={{
                readOnly: true,
                style: { fontWeight: 'bold' },
              }}
            />
          </Grid>

          <Grid xs={12} container spacing={1} sx={{ ml: 1, mt: 1 }}>
            {payrolDetails?.paymentTypeTotals.map((paymentTypeTotal) => (
              <Grid
                xs={12}
                md={6}
                key={paymentTypeTotal.paymentType}
                sx={{ mt: 1 }}
              >
                <TextField
                  label={`Total ${paymentTypeTotal.paymentType}`}
                  value={fNumber(paymentTypeTotal.totalPayment)}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    style: { fontWeight: 'bold' },
                  }}
                />
              </Grid>
            ))}
          </Grid>

          {payrollApprovers.some((approver) => approver.approved) && (
            <>
              <Grid xs={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
                <Divider style={{ width: '100%' }} />
              </Grid>
              <Grid xs={12} container spacing={1} sx={{ ml: 1 }}>
                <Grid xs={12}>
                  <Typography variant='h6'>Planilla aprobada por</Typography>
                  <List dense={true}>
                    {payrollApprovers
                      .filter((approver) => approver.approved)
                      .map((approver) => (
                        <ListItem key={approver.id}>
                          <ListItemIcon>
                            <CheckBoxIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${approver.firstName} ${approver.lastName}`}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Grid>
              </Grid>
            </>
          )}

          <Grid xs={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>

          {isAnyCheckboxEmployeeSelected && (
            <Tooltip title='Pagar' enterTouchDelay={0}>
              <Fab
                color='primary'
                sx={{
                  position: 'fixed',
                  bottom: 16,
                  right: 16,
                  bgcolor: green[500],
                  '&:hover': { bgcolor: green[600] },
                }}
              >
                <PriceCheckIcon />
              </Fab>
            </Tooltip>
          )}
          <PayrollEmployeeWorkDetails
            open={openPayrollDetailPopup}
            onClose={onClosePayrollEmployeeWorkDetailsDialog}
            employeeWorkDetails={selectedEmployeeWorkDetail!}
            isDeleteAction={true}
            payrollId={payrollId}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default PayrollPayIncluded